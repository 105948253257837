* {
  box-sizing: border-box;
}

.App-container {
  background-color: rgb(221, 221, 221, 0.9);
  padding-bottom: 50px;
}

.dark .App-container  {
  background-color: rgb(32, 32, 32, .75);
}

body {
  text-align: center;
  background-color: #dddddd;
  background-image: none;
  background-repeat: repeat;
  background-size: 200px 200px;
}

.Row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Row-separator.Row-letter {
  background-color: rgb(87, 172, 120);
  flex: 1;
  max-width: 40px;
  height: 2px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  margin: 10px 0px 10px 0px;
  border: 0px;
}

.App-container.color-blind .Row-separator {
  background-color: #85c0f9;
}

.NextPrev {
  font-weight: bold;
  font-size: 40px;
  line-height: .5;
}

.DayNum {
  font-size: 18px;
}

.Row-letter {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  cursor: default;
}

.Row-letter-color {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.Row-annotation {
  margin-inline-start: 16px;
  width: 5em;
  text-align: end;
  margin-left: -80px;
  padding-left: 80px;
}

.App-container {
  position: relative;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  z-index: 2;
}

.App-container h1 {
  margin-top: 0;
  margin-bottom: 5px;
}

.Game,
h1 {
  user-select: none;
}

.Game {
  display: flex;
  flex-direction: column;
}

table.Game-rows {
  margin: auto;
  display: flex;
  flex-direction: column;
}

table.Game-rows:focus {
  outline: none;
}

table.Game-rows > tbody {
  display: flex;
  flex-direction: column;
}

.Game-keyboard {
  display: flex;
  flex-direction: column;
}

.Game-keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.Game-keyboard-button {
  margin: 2px;
  background-color: #cdcdcd;
  padding: 2px;
  text-transform: capitalize;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 20px;
  color: inherit;
  text-decoration: inherit;
  border: 2px solid transparent;
  cursor: pointer;
}

.Game-keyboard-button-wide {
  flex: 2;
}

.Game-keyboard-button:focus {
  outline: none;
}

.letter-correct, .letter-correct-fin {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgb(87, 172, 120);
  color: white !important;
}

.letter-elsewhere, .letter-elsewhere-fin {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgb(233, 198, 1);
  color: white !important;
}

.letter-correct-fin {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(87, 172, 120);
  color: white !important;
}

.letter-elsewhere-fin {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(233, 198, 1);
  color: white !important;
}

.letter-absent, 
.Game-keyboard-button.letter-correct-fin,
.Game-keyboard-button.letter-elsewhere-fin,
.App-container.color-blind .Game-keyboard-button.letter-correct-fin, 
.App-container.color-blind .Game-keyboard-button.letter-elsewhere-fin {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgb(162, 162, 162);
  color: white !important;
}

.Typing-known-absent {
  border: 1px dotted white;
}

.Typing-known-elsewhere{
  border: 1px dotted rgba(233, 198, 1);
}

.Typing-known-correct {
  border: 1px solid rgba(87, 172, 120);
}

body.dark {
  background-color: #202020;
  color: #e0e0e0;
  color-scheme: dark;
}

body.dark .Game-keyboard-button {
  color: #404040;
}

a,
a:visited {
  color: #8080ff;
}

a:active {
  color: #cc77ff;
}

.Game-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.Game-options > * + * {
  margin-inline-start: 0.5rem;
}

.Game-options button {
  min-width: 4rem;
}

.Game-name-mode-container {
}

.Game-name {
  color: inherit;
  font-style: inherit;
  margin-right: 5px;
  display: inline-block;
}

.Game-modes {
  box-sizing:unset;
  margin-top: -10px;
}

.Game-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  width: 100%;
}

.Game-stats h1 {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
}

.Game-stats-notice {
  font-size: 12px;
  text-align: center;
  max-width: 300px;
  margin-top: 10px;
  text-align: left;
}

.Game-stats-games {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.stat {
  flex: 1;
  margin: 0 10px;
}

.stat-num {
  font-size: 36px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  font-variant-numeric: proportional-nums;
}

.stat-label {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Game-stats-guesses {
  padding-bottom: 10px;
  width: 80%;
}

.guess-stat {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  margin: 3px;
}

.guess-count {
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  padding-top: 3px;
}

.guess-graph {
  width: 100%;
  height: 100%;
  padding-left: 4px;
    border-radius: 5px;
}

.guess-bar {
  height: 20px;
  line-height: 20px;
  width: 0%;
  position: relative;
  background-color: black;
  display: flex;
  justify-content: flex-end;
  padding-right: 7px;
  border-radius: 5px;
}

.guess-games {
  font-weight: bold;
  color: white;
  height: 20px;
  line-height: 20px;
}

.App-footer {
  margin: -1rem 0 2rem 0;
  font-size: 80%;
  line-height: 1.5;
}

.App-about {
  margin-top: -1rem;
  line-height: 1.4;
  text-align: left;
  padding: 15px;
}

.App-about b {
  background-color: #888;
  color: white;
  padding: 1px 3px;
  border-radius: 2px;
}

.App-about b.green-bg {
  background-color: rgb(87, 172, 120);
}

.App-about b.yellow-bg {
  background-color: #e9c601;
}

.Game-seed-info {
  opacity: 0.7;
  margin-top: 1em;
  font-variant-numeric: tabular-nums;
}

.Game-sr-feedback,
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.Settings {
  text-align: left;
  font-size: 18px;
}

.Settings-setting {
  margin: 8px;
  display: flex;
  align-items: top;
}

.Settings-setting input {
  width: 18px;
  height: 18px;
}

.Settings-setting input[type="range"] {
  width: 50px;
  height: 18px;
}

.Settings-setting label {
  margin-inline-start: 8px;
  margin-inline-end: 8px;
}

.top-right {
  position: absolute;
  top: 5px;
  right: 5px;
}

.emoji-link {
  font-size: 24px;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.top-right > * + * {
  margin-inline-start: 8px;
}

.App-container.color-blind .letter-correct,
.App-container.color-blind .letter-correct-fin,
.App-container.color-blind .App-about b.green-bg {
  background-color: #f5793a;
}

.App-container.color-blind .letter-elsewhere,
.App-container.color-blind .letter-elsewhere-fin,
.App-container.color-blind .App-about b.yellow-bg {
  background-color: #85c0f9;
}

.App-about,
.react-calendar,
.Settings {
  border:  1px solid black;
  border-radius: 5px;
  background-color: rgb(221, 221, 221);
}

.dark .App-about,
.dark .react-calendar,
.dark .Settings  {
  border: 1px solid white;
  border-radius: 5px;
  background-color: rgb(32, 32, 32);
}

.react-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
}

.react-calendar__tile pre {
  height: 100%;
  font-size: 6px;
}

a { 
  text-decoration: inherit;
}

.practiceLink {
  padding: 3px;
  border-radius: .375rem;
}

a.practiceLink:hover {
  background-color:  rgb(72, 77, 87);
  border-radius: .375rem;
}

.ModeEnabled {
  border-radius: .375rem;
  padding: .3rem .45rem;
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-decoration: inherit;
  transition-property: color,background-color,border-color,fill,stroke,-webkit-text-decoration-color;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,-webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
 
}

.ModeEnabled, .ModeDisabled:hover {
  background-color: rgb(72, 77, 87) !important;
  color: rgb(209 213 219);
}

.ModeEnabled.dark, .ModeDisabled.dark:hover {
  background-color: rgb(65, 72, 82) !important;
  color: rgb(209 213 219);
}

.ModeDisabled
{
  border-radius: .375rem;
  padding: .3rem .45rem;
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-decoration: inherit;
  color: rgb(209 213 219);
  transition-property: color,background-color,border-color,fill,stroke,-webkit-text-decoration-color;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,-webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
}

.News {
  text-align: justify;
}

.popup-content {
  text-align: left;
}

.stacks3 {
  --stacks: 3;
}

.index0 {
  --index: 0;
}

.index1 {
  --index: 1;
}

.index2 {
  --index: 2;
}

.stack {
  display: grid;
  grid-template-columns: 1fr;
}

.stack span {
  grid-row-start: 1;
  grid-column-start: 1;

  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 340ms cubic-bezier(.46,.29,0,1.24) 1 backwards calc(var(--index) * 120ms), glitch 2s ease 3 2s alternate-reverse;
}

.stack span:nth-child(odd) { --glitch-translate: 8px; }
.stack span:nth-child(even) { --glitch-translate: -8px; }

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
  };
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  4%, 100% {  text-shadow: none; transform: none; }
}

.rewardImage {
  width: 300px;
  height: 300px;
}

.rewardImageLink {
  
}

.Gallery {
  
}

.gallery-author {
  margin-top: 20px;
  font-size: 28px;
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 15px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dark.gallery-author {
  color: #d0d0d0;
  text-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

.link-Image {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #aaa;

  font-size: 24px;
  text-decoration: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin: 1px;

  width: 32px;
  height: 32px;

  display: block;
}


.link-About {
  background-image: url("info.png");  
}

.link-Stats {
  background-image: url("stats.png");  
}

.link-Settings {
  background-image: url("settings.png");  
}

.link-Calendar {
  background-image: url("calendar.png");  
}

.link-Gallery {
  background-image: url("gallery.png");  
}

.collapsible {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 5px;
  border: none;
  text-align: left;
  outline: none;
  font-size: 18px;
  background-image: url("collapse-arrow.png");  
}

.collapsible.active {
  background-image: url("expand-arrow.png");  
}

.active, .collapsible:hover {
  background-color: #555;
}

.collapsible:after {
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
}

.content {  
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
  margin-top: 32px;
}

.menuCollapsed.content {
  max-height: 0px;;
}

.menuExpanded.content{
  max-height: 500px;
}

.flowDown {
  flex: 1;
  flex-direction: column;;
}

.popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  visibility: visible;
  opacity: 1;
  z-index: 1000;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  color: black;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}

.popup .popup-close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .popup-close:hover {
  color: #06D85F;
}

.popup .popup-content {
  max-height: 30%;
  overflow: auto;
}

.limerick {
  display: flex;
  justify-content: center;
  width: 100%;
}

.limerick-text {
  text-align: left;
  white-space: pre-wrap;
}

@media screen and (max-width: 700px){
  .box{
    width: 70%;
  }
  .popup{
    width: 70%;
  }
}

.imageCredit {
  margin-top: 20px;
  font-size: 10px;  
}

.copy-inner-text-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  margin: 4px 2px;
  cursor: pointer;
}

.import-code {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: monospace;
  resize: none;
}

.news-announcement {
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.news-announcement h2 {
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: bold;
  text-shadow: none;
  background: white;
  padding: 10px;
  border-radius: 8px;
}

.dark .news-announcement h2 {
  color: #333;
  background: #e0e0e0;
}

.news-content {
  font-size: 16px;
  line-height: 1.5;
  margin: 15px 0;
}

.news-content ul {
  list-style: none;
  padding: 0;
  margin: 15px 0;
  text-align: left;
}

.news-content li {
  margin: 10px 0;
  padding-left: 25px;
  position: relative;
}

.news-content li::before {
  content: attr(data-emoji);
  position: absolute;
  left: 0;
}

.news-cta {
  margin-top: 20px;
}

.news-button {
  display: inline-block;
  padding: 10px 25px;
  background: linear-gradient(45deg, #4CAF50, #45a049);
  color: white !important;
  text-decoration: none;
  border-radius: 25px;
  font-weight: bold;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.news-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  color: white !important;
}

.news-button:visited {
  color: white !important;
}

.dark .news-button {
  background: linear-gradient(45deg, #5cb85c, #4cae4c);
  color: white !important;
}